import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom"
import Home from '../Home/Home'
import About from '../About/About'
import ContactPage from '../ContactPage/ContactPage'
import DonationPage from '../DonationPage/DonationPage'
import Whatwedo from '../Whatwedo/Whatwedo'
export default function MainRoutes() {



    return (
        <Routes>
            <Route path="/" element={<Navigate replace to="home" />} />
            <Route path="home" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="donation" element={<DonationPage />} />
            <Route path="activity" element={<Whatwedo />} />
        </Routes >
    )
}