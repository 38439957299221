import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DonateIcon from '@mui/icons-material/VolunteerActivism';
import AppBar from '@mui/material/AppBar';
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import ButtonGroup from '@mui/material/ButtonGroup';
import './Homepage.css'
import Logo from '../../logos/NITSUTS BERASHIT.jpg'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import 'react-awesome-slider/dist/styles.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Home from '../Home/Home'
import Footer from '../Footer/Footer'
import MainRoutes from '../Router/MainRoutes'
import { Link } from "react-router-dom";



export default function Homepage() {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar sx={{ backgroundColor: '#c07560' }}>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontFamily: "David" }}>
                            "נפלא כוחו של אדם המביט בניצן ורואה בו את הפרח. אשרי האדם המבין כי גם הניצן המתקשה להיפתח ראוי לפרוח."
                        </Typography>
                        <ButtonGroup size="small" aria-label="small button group" sx={{ marginLeft: 'auto' }}>
                            <Button target="_blank" href="https://instagram.com/nitzosbersit?igshid=YmMyMTA2M2Y=" color="inherit"><InstagramIcon /></Button>
                            <Button target="_blank" href="https://www.facebook.com/nitzosbersit" color="inherit"><FacebookIcon /></Button>
                        </ButtonGroup>
                    </Toolbar>
                </AppBar>
            </Box>
            <Container maxWidth="lg" >
                <Paper >
                    <Box className="header" >
                        <Grid container>
                            <Grid item xs={3}>
                                <img className="logo" src={Logo} style={{ height: "150px" }}></img>
                            </Grid>
                            <Grid item xs={10} md={8}>
                                <ButtonGroup className="mainmenu" size="large" variant="text" aria-label="text button group" sx={{ color: '#425257' }} color="neutral">
                                    <Link to="/home" style={{ textDecoration: 'none', color: "inherit", fontWeight: 'bold' }}>
                                        <Button color="inherit" sx={{ fontWeight: 'bold' }}>דף הבית</Button>
                                    </Link>
                                    <Link to="/about" style={{ textDecoration: 'none', color: "inherit", fontWeight: 'bold' }}>
                                        <Button color="inherit" sx={{ fontWeight: 'bold' }}>מי אנחנו</Button>
                                    </Link>
                                    <Link to="/activity" style={{ textDecoration: 'none', color: "inherit", fontWeight: 'bold' }}>
                                        <Button color="inherit" sx={{ fontWeight: 'bold' }}>מה אנחנו עושים</Button>
                                    </Link>
                                    <Link to="/contact" style={{ textDecoration: 'none', color: "inherit", fontWeight: 'bold' }}>
                                        <Button color="inherit" sx={{ fontWeight: 'bold' }}>צור קשר</Button>
                                    </Link>
                                    <Link to="/donation" style={{ textDecoration: 'none', color: "inherit", fontWeight: 'bold' }}>
                                        <Button className="donatebutton" variant="outlined" color="nitzos" endIcon={<DonateIcon />} sx={{ fontWeight: 'bold' }}>
                                            לתרומה
                                        </Button>
                                    </Link>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </Box>
                    <MainRoutes />
                    <Footer />
                </Paper >
            </Container >
        </div >
    )
}


function Item(props) {
    return (
        <Paper>
            <h2>{props.item.name}</h2>
            <p>{props.item.description}</p>

            <Button className="CheckButton">
                Check it out!
            </Button>
        </Paper>
    )
}




