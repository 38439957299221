import React from 'react'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";


export default function DonationPage() {




    return (
        <Container maxWidth="md">
            <Grid container spacing={{ xs: 2, md: 2, lg: 2 }} className="donation">
                <Grid item >
                    <Card >
                        <CardContent>
                            <Typography variant="title" component="h3" align="center" color="#425257" gutterBottom>
                                תרומה כספית
                            </Typography>
                            <Typography variant="title" component="h4" color="#425257" gutterBottom>
                                להלן דרכי התרומה האפשריות:
                            </Typography>
                            <Typography variant="title" component="h5" align="center" color="#425257" gutterBottom>
                                1. אפליקציות ביט או פייבוקס למספר - 0529465458
                            </Typography>
                            <Typography variant="title" component="h5" color="#425257" gutterBottom>
                                2.העברה בנקאית - <br></br>
                            </Typography>
                            <Typography variant="title" component="h5" color="#425257" align="center" gutterBottom>
                                בנק דיסקונט <br></br>
                                מספר סניף - 112 (גדרה)<br></br>
                                מס' חשבון - 0186666723
                            </Typography>
                        </CardContent>
                        <CardActions>

                        </CardActions>
                    </Card>
                </Grid>
                <Grid item >
                    <Card>
                        <CardContent>
                            <Typography variant="title" component="h3" align="center" color="#425257" gutterBottom>
                                לתרומה אחרת - <br></br>
                                כמו שיתוף ידע, הרצאות ועוד,<br></br>
                                מלאו פרטים ואנו ניצור עמכם קשר בהקדם.
                            </Typography>
                        </CardContent>
                        <CardActions>

                        </CardActions>
                        <Link to="/contact" style={{ textDecoration: 'none', color: "inherit", fontWeight: 'bold' }}>
                            <Button className="contactbuttondonationpage" variant="contained" color="neutral" size="large" sx={{ fontWeight: 'bold' }}>
                                צור קשר
                            </Button>
                        </Link>
                    </Card>
                </Grid>
            </Grid>
            <Typography variant="title" component="h3" align="center" color="#425257" gutterBottom style={{ marginTop: 25 }}>
                עמותת ניצוץ בראשית (ע"ר 580764295) איננה מוציאה משכורות לפעיליה ו/או למייסדיה, <br></br>
                וכלל התרומות מועברות באופן ישיר אל מי שנזקק אליהן.<br></br>
                התרומות לעמותה מוכרות לצרכי מס על פי סעיף 46 לפקודת מס הכנסה.
            </Typography>
        </Container>
    )
}