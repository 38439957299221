import logo from './logo.svg';
import './App.css';
import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import Homepage from './components/Homepage/Homepage';
import { BrowserRouter } from "react-router-dom";


const theme = createTheme({
  direction: 'rtl', // Both here and <body dir="rtl">
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#0971f1',
      darker: '#053e85',
    },
    nitzos: {
      main: '#c07560',
      contrastText: "#ffffff"
    },
    neutral: {
      main: '#425257',
      contrastText: '#fff',
    },
  },
});
// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});



function App() {

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <div dir="rtl">
            <Homepage />
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;





// return (
//   <div className="App">
//     <header className="App-header">
//       <img src={logo} className="App-logo" alt="logo" />
//       <p>
//         Edit <code>src/App.js</code> and save to reload.
//       </p>
//       <a
//         className="App-link"
//         href="https://reactjs.org"
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         Learn React
//       </a>
//     </header>
//   </div>
// );