import React, { useEffect } from 'react'
import { googleFormsToJson, GoogleFormProvider, useGoogleForm, useShortAnswerInput } from 'react-google-forms-hooks'


export default function ContactPage(props) {


    return (
        <iframe
            className="contactform"
            src="https://docs.google.com/forms/d/e/1FAIpQLSdU_2wH_bcm-Cpac85sr1qOhes8wEWIU99QcsnLvB5icpOhVA/viewform?embedded=true"
            frameborder="0"
            marginheight="0"
            arginwidth="0">טוען....
        </iframe>
    )
}


