import React from 'react'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Paper from '@mui/material/Paper';
import hooman from '../../slideshowimages/hooman.jpg'
import partner from '../../slideshowimages/partner.jpg'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';


export default function Whatwedo(props) {

    const bull = (
        <Box
            component="span"
            sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
            •
        </Box>
    );

    return (
        <Container maxWidth="md" sx={{ marginTop: 3 }}>
            <Paper>
                <Typography variant="title" component="h1" align="center" color="#425257" gutterBottom>
                    מה אנחנו עושים?
                </Typography>
                <p style={{ fontSize: 18, fontWeight: "normal", whiteSpace: "pre-line" }}>
                    <b>1. הענקת ציוד לילדים שעולים לכיתה א'-</b><br></br>
                    לכל ילד מגיע את הזכות להתחיל את הלימודים ברגל ימין ובצורה מכובדת. <br></br>
                    עמותת ניצוץ בראשית מעניקה את הציוד הראשוני לילדים שעולים לכיתה א' בכדי להרגיש שווים.<br></br>
                    <br></br>
                    <b>2. חגיגות בר ובת מצווה לילדים מועטי יכולת -</b><br></br>
                    בשיתוף עם עמותת "עזר מדוד" חוגגים בכל שנה בר מצווה או בת מצווה לילדים שידם אינם משגת.<br></br>
                    חגיגות אלו מחולקות לסיור בכותל, הענקת ציצית ותפילין לבנים, סיור בירושלים ובנוסף אירוע מכובד באולם יחד עם המשפחות.
                    <br></br>
                    <br></br>
                    <b>3. תרומת משחקים לילדים שנמצאים במעון לנשים מוכות -</b><br></br>
                    הענקת משחק אישי לכל ילד שמגיע למקום המורכב אך מחבק בכדי להקל על תהליך ההסתגלות שלו במעון.<br></br>
                    <br></br>
                    <b>4. חלוקת משחקים לילדים חולים בשניידר -</b><br></br>
                    במקום הכי מאתגר ובזמנים הכי מורכבים לאפשר דקה אחת של נחת וחיוך אחד קטן.<br></br>
                    <br></br>
                    <b>5. הכנה לקראת צו ראשון -</b><br></br>
                    גיוס לצבא הינו צעד משמעותי שיכול להיות משנה חיים.<br></br>
                    לרוב הנערים והנערות לא מודעים לחשיבות צו הראשון. מטרת העמותה היא להכין אותם לקראת האירוע המשמעותי הזה על ידי תרגול המבחנים וראיונות בכדי שיגיעו מוכנים וימצאו את הפוטנציאל הרב שטמון בהם.
                    <br></br>
                    <br></br>
                    <b>6. תמיכה בחיילים/ות מועטי יכולת -</b><br></br>
                    העמותה מלווה את המתגייסים ותומכת בהם בציוד הנדרש ובתיאום עם המפקדים בצבא.
                </p>
            </Paper>
        </Container>
    )
}