import React from 'react'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Link } from "react-router-dom";

export default function DonationsBlock() {


    return (
        <Paper variant="outlined" square className="donations" >
            <Typography variant="title" component="h1" align="center" color="#425257" gutterBottom>
                אצלנו כל אחד נותן לפי יכולתו
            </Typography>
            <Typography variant="h3" component="h2" align="center" color="black" gutterBottom sx={{ fontSize: "36px" }}>
                תרומה יכולה לבוא באלפי צורות - <br></br>
                אם אתם מרגישים שאתם יכולים לתרום מהידע שלכם <br></br>
                ולאו דווקא מהכיס, <br></br>
                להעניק לילדים ונוער ידע מקצועי שיעזור להם בעתיד,<br></br>
                נשמח לשמוע מכם!
            </Typography>
            <Typography variant="body2" align="center" >
                <Link to="/contact" style={{ textDecoration: 'none', color: "inherit", fontWeight: 'bold' }}>
                    <Button className="donatebutton" variant="contained" color="neutral" size="large" sx={{ fontWeight: 'bold', marginTop: "20px" }}>
                        לחצו כאן
                    </Button>
                </Link>
            </Typography>
        </Paper >
    )




}