import React from 'react'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import CallIcon from '@mui/icons-material/Call';
import PlaceIcon from '@mui/icons-material/Place';
import EmailIcon from '@mui/icons-material/Email';
import InfoIcon from '@mui/icons-material/Info';
import FlowerLogo from '../../logos/04.png'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '../../logos/Icons/instagram.png'
import TiktokIcon from '../../logos/Icons/tiktok.png'
import Button from '@mui/material/Button';

export default function Footer() {
    const d = new Date();
    let year = d.getFullYear();



    return (
        <Paper variant="outlined" square className="footer" >
            <Grid container spacing={{ xs: 2, md: 5, lg: 10 }}>
                <Grid item xs={6}>
                    <Typography variant="title" component="h1" align="center" color="#425257" gutterBottom>
                        צרו קשר
                    </Typography>
                    <List className="contactlist" sx={{ width: '100%', maxWidth: 360 }}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <CallIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="052-9465458" />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <EmailIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="info@nitzosb.com" />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <PlaceIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="בניה 38, מושב בניה" />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <InfoIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="עמותה רשומה מס' 580746295" />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="title" component="h1" align="center" color="#425257" gutterBottom className='nitzosLogoFooter'>
                        <img src={FlowerLogo} height="170px" />
                    </Typography>
                    <Typography variant="title" component="h1" align="center" color="#425257" gutterBottom className='socialTitleFooter'>
                        גם ברשתות החברתיות
                    </Typography>
                    <List className="socialIconsList" sx={{ maxWidth: 80, display: 'flex', flexDirection: 'row', padding: 0 }} >
                        <ListItem>
                            <Button target="_blank" href="https://www.facebook.com/nitzosbersit" color="inherit">
                                <FacebookIcon color="primary" sx={{ fontSize: '60px' }} />
                            </Button>
                        </ListItem>
                        <ListItem>
                            <Button target="_blank" href="https://instagram.com/nitzosbersit?igshid=YmMyMTA2M2Y=" color="inherit">
                                <img src={InstagramIcon} height="54px" />
                            </Button>
                        </ListItem>
                        {/* <ListItem>
                            <img src={TiktokIcon} height="54px" />
                        </ListItem> */}
                    </List>
                </Grid>
            </Grid>
            <Typography variant="title" component="h5" align="center" color="#425257" gutterBottom style={{ marginTop: 25 }}>
                עמותת ניצוץ בראשית (ע"ר 580764295) איננה מוציאה משכורות לפעיליה ו/או למייסדיה, <br></br>
                וכלל התרומות מועברות באופן ישיר אל מי שנזקק אליהן.<br></br>
                התרומות לעמותה מוכרות לצרכי מס על פי סעיף 46 לפקודת מס הכנסה.
            </Typography>
            {/* <Typography variant="title" component="h5" color="#425257" gutterBottom className="credit" align="left">
                נבנה ע"י אור באכתרי @ {year}
            </Typography> */}
        </Paper>


    )
}