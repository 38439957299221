import React from 'react'
import Image1 from '../../slideshowimages/image1.jpg'
import Image2 from '../../slideshowimages/image2.jpeg'
import Image3 from '../../slideshowimages/image3.jpeg'
import Image4 from '../../slideshowimages/image4.jpeg'
import Image5 from '../../slideshowimages/image5.jpg'
import Image6 from '../../slideshowimages/image6.jpg'
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import OurActivityBlock from '../OurActivityBlock/OurActivityBlock';
import DonationsBlock from '../DonationsBlock/DonationsBlock';
import Footer from '../Footer/Footer'

export default function Home() {
    const AutoplaySlider = withAutoplay(AwesomeSlider);


    return (
        <div>
            <AutoplaySlider
                play={true}
                cancelOnInteraction={false} // should stop playing on user interaction
                interval={6000}
                style={{ marginTop: 10 }}
                buttons={true}
            >
                <div data-src={Image1} />
                <div data-src={Image2} />
                <div data-src={Image3} />
                <div data-src={Image4} />
                <div data-src={Image5} />
                <div data-src={Image6} />
            </AutoplaySlider>
            <OurActivityBlock />
            <DonationsBlock />


        </div>



    )
}