import React from 'react'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Paper from '@mui/material/Paper';
import hooman from '../../slideshowimages/hooman.jpg'
import partner from '../../slideshowimages/partner.jpg'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function About() {
    const bull = (
        <Box
            component="span"
            sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
            •
        </Box>
    );


    return (
        <Container maxWidth="md">
            <Paper>
                <Grid container spacing={2} className="about">
                    <Grid item xs={4}>
                        <Card >
                            <CardMedia component="img" height="300" image={hooman} />
                            <CardActions>
                                <Typography variant="title" component="h3" align="center" color="#425257" gutterBottom>
                                    הומן גושה, מייסד
                                </Typography>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card >
                            <CardMedia component="img" height="300" image={partner} />
                            <CardActions>
                                <Typography variant="title" component="h3" align="center" color="#425257" gutterBottom>
                                    קובי פרג', מייסד
                                </Typography>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                <p style={{ fontSize: 18, fontWeight: "normal" }}>
                    זהו סיפור על שני חברים שמגיעים מרקע שונה, ובכל אחד מהם בער הרצון להצליח באופן אישי ולעשות משהו טוב למען העולם.<br></br>
                    דרכיהם הצטלבו אי שם בשנת 1993, ובמרוץ השנים התרחקו והתקרבו אך תמיד נשארו במרחק סביר האחד מהשני, כנראה כך רצה הגורל.<br></br>
                    השניים למדו יחד מכיתה י'-י"ג אך ובכיתה י"ד (הנדסאי אלק' ומחשבים) התפצלו. הומן המשיך ללמוד בעמל ב' פ"ת וקובי בעמ"ל ברמלה. השניים המשיכו להיפגש, בילו יחד והשתדלו תמיד לעשות טוב, למשל להכין סנדוויצ'ים ולחלק לנזקקים.<br></br>
                    ושוב, כמו בסיפור טוב - השניים נפגשים שוב בצבא ושוב הם נפרדים. קובי ממשיך לתפקיד במודיעין והומן ממשיך לקריירה ענפה וארוכה בחה״א.<br></br>
                    קובי סיים שירות, טייל בחו"ל והחל לימודי הנדסה והומן ממשיך לטפח את הקריירה הצבאית בתפקידים פיקודיים, טכנולוגיים ומבצעיים.<br></br>
                    החברות נמשכה ונשמרה לאורך השנים, השניים גדלו והתפתחו בפן האישי - לימודים, תארים, קריירה, משפחה.<br></br>
                    ובכל הזמן הזה - החברים הטובים משתפים חוויות, מחשבות, מחליפים רעיונות.<br></br>
                    בשנת 2021 הם נפגשים שוב - קובי חושב על מיזם בתחום הרכיבים האלקטרונים ומגייס את הומן לרעיון, ביחד השניים מקימים את חברת NETSIGHT ONE.<br></br>
                    וכמו בימים הטובים, הם עדיין חושבים איך לעשות טוב לעולם ולהפוך אותו למקום טוב יותר וביחד הם מחליטים להקים את עמותת ״ניצוץ בראשית״ שהמטרה שלה היא לתרום לחברה ולתת סיכוי שווה ותקווה אמיתית לילדים ולנוער.
                </p>
            </Paper>
        </Container>
    )
}