import React from 'react'
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CooperationIcon from '../../logos/Icons/cooperation.png'
import HeartIcon from '../../logos/Icons/heart.png'
import DonationIcon from '../../logos/Icons/donation.png'
import { Link } from "react-router-dom";

export default function OurActivityBlock() {




    return (
        <Paper variant="outlined" square className="ouractivity">
            <Typography variant="title" component="h1" align="center" color="#425257" gutterBottom>
                הפעילות שלנו
            </Typography>
            <Grid container spacing={{ xs: 3, md: 3, lg: 3 }} className="activitycardsblock">
                <Grid item >
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography variant="title" component="h2" align="center" color="#425257">
                                חיבור בין עמותות
                            </Typography>
                            <img src={CooperationIcon} height="150px" style={{ marginRight: "50px", marginTop: "15px" }} />
                            <Typography variant="body2" align="center" >
                                <Link to="/activity" style={{ textDecoration: 'none', color: "inherit", fontWeight: 'bold' }}>
                                    <Button className="donatebutton" variant="contained" color="nitzos" sx={{ fontWeight: 'bold', marginTop: "20px" }}>
                                        מידע נוסף
                                    </Button>
                                </Link>
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {/* <Button size="medium" >Learn More</Button> */}
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item >
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography variant="title" component="h2" align="center" color="#425257">
                                סיוע לילדים ונוער
                            </Typography>
                            <img src={HeartIcon} height="150px" style={{ marginRight: "50px", marginTop: "15px" }} />
                            <Typography variant="body2" align="center" >
                                <Link to="/activity" style={{ textDecoration: 'none', color: "inherit", fontWeight: 'bold' }}>
                                    <Button className="donatebutton" variant="contained" color="nitzos" sx={{ fontWeight: 'bold', marginTop: "20px" }}>
                                        מידע נוסף
                                    </Button>
                                </Link>
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {/* <Button size="medium" >Learn More</Button> */}
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item >
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography variant="title" component="h2" align="center" color="#425257">
                                תרומות עבור מועטי יכולת
                            </Typography>
                            <img src={DonationIcon} height="150px" style={{ marginRight: "80px", marginTop: "15px" }} />
                            <Typography variant="body2" align="center" >
                                <Link to="/donation" style={{ textDecoration: 'none', color: "inherit", fontWeight: 'bold' }}>
                                    <Button className="donatebutton" variant="contained" color="nitzos" sx={{ fontWeight: 'bold', marginTop: "20px" }}>
                                        מידע נוסף
                                    </Button>
                                </Link>
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {/* <Button size="medium" >Learn More</Button> */}
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Paper>



    )

}